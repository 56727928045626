const Footer = () => {
  return (
    <div className="footer-field">
      <p className="footer">
        ©2022 by JHE-WEI, JHANG
      </p>
    </div>
  );
}

export default Footer;
